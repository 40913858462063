import './App.css';
import React, {useState} from 'react';
import Navigation from './components/Navigation/Navigation';
import {Router, Redirect, Route, Switch} from "react-router-dom";
import GuestRoutes from "./routes/Guest";
import {SnackbarProvider} from 'notistack';
import {useIdleTimer} from "react-idle-timer";
import history from "./services/history";

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(
        localStorage.getItem('isAuthenticated') === 'true' || false
    );

    const login = (user) => {
        console.log(user);
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('user', JSON.stringify(user));
        history.push('/orders');
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.setItem('isAuthenticated', false);
        localStorage.setItem('user', null);
        history.push('/login');
    };

    const handleOnIdle = event => {
        logout();
    }

    useIdleTimer({
        timeout: 1000 * 60 * process.env.REACT_APP_SESSION_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500
    });

    // if (process.env.NODE_ENV !== 'development') {
    //     console.log = () => {};
    //     console.warn = () => {};
    //     console.error = () => {};
    // }

    return (

        <Router history={history}>
            <SnackbarProvider maxSnack={3} style={{ whiteSpace: 'pre-line' }}>
                {isAuthenticated ? (
                    <Navigation isAuthenticated={isAuthenticated} handleLogout={logout} />
                ) : (
                    <Switch>
                        {GuestRoutes.map((route) => (
                            <Route exact path={route.route} key={route.route}>
                                <route.component isAuthenticated={isAuthenticated} handleLogin={login} />
                            </Route>
                        ))}
                        <Redirect to='/login' isAuthenticated={isAuthenticated} handleLogin={login} />
                    </Switch>
                )}
            </SnackbarProvider>
        </Router>
    )
};

export default App;