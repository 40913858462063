import React, {useEffect, useState} from 'react';
import {AppBar, Paper, Tab} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useParams } from 'react-router-dom';
import api from "../../../services/api";
import {parseResponse, unslugify} from "../../../services/helpers";
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/core/styles";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import Table from "../../../components/Table/Table";

const useStyles = makeStyles((theme) => ({
    basePaper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        background: theme.background,
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 815
    },
    headers: {
        marginBottom: 15,
    }
}));

export const Show = (props) => {

    const classes = useStyles();
    const [tab, setTab] = useState("1");
    const [headers, setHeaders] = useState([]);
    const [panels, setPanels] = useState([]);
    const [tabs, setTabs] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const {id} = useParams();
    const {changeTitle} = props;

    useEffect(() => {
        changeTitle(`${props.title} ${id}`);

        api.get(`${props.url}/${id}`)
            .then(response => {
                const responseData = response.data.data

                const headers = [];
                const tabs = [];
                const panels = [];
                let index = 0;
                for (const [key, value] of Object.entries(responseData)) {
                    if (!value || (value && typeof value !== 'object')) {
                        // It's a top level element (key value pair where the value is a string or int).
                        headers.push(
                            <Grid item md={4} xs={2}>
                                <Typography variant="subtitle2" >{unslugify(key)}</Typography>
                                <Typography variant="body1" gutterBottom>{value}</Typography>
                            </Grid>
                        );
                    }else{
                        // It's a tab/panel element (it's a complex element like an array or object).

                            if(index === 0){
                                setTab(key);
                                index++
                            }
                            tabs.push(<Tab label={unslugify(key)}  value={key} id={`wrapped-tab-${key}`} aria-controls={`wrapped-tabpanel-${key}`} />);

                        // array
                        if (value && value.length) {
                            // console.log('its an array', key, value);
                            // const columnKeys = Object.keys(value[0])
                            // const columnNames = columnKeys.map(k => { return { field: k, headerName: unslugify(k) }});
                            panels.push(
                                <TabPanel value={key}>
                                    <Table
                                        meta_name='transactionlines'
                                        url={`transactionlines?transaction_id=${id}`}
                                        autoHeight={true}
                                        // rows={value ? value : []}
                                        // columns={columnNames}
                                    />
                                </TabPanel>
                            );
                        } else if (value && !value.length && typeof value == 'object') {
                            // console.log('its just an object', key, value);
                            const items = [];
                            for (const [objectKey, objectValue] of Object.entries(value)) {
                                items.push(
                                    <Grid item md={4} xs={2}>
                                        <Typography variant="subtitle2" >{unslugify(objectKey)}</Typography>
                                        <Typography variant="body1" gutterBottom>{objectValue}</Typography>
                                    </Grid>
                                );
                            }
                            panels.push(
                                <TabPanel value={key}>
                                    <Grid className={classes.headers} container spacing={3}>
                                        {items && items.length ? items : <Typography variant="body1">No {key}</Typography>}
                                    </Grid>
                                </TabPanel>
                            );
                        }
                        // <TabPanel value="1">Item One</TabPanel>
                    }
                }
                
                setHeaders(headers);
                setTabs(tabs);
                setPanels(panels);
            })
            .catch(error => enqueueSnackbar(parseResponse(error), {variant: 'warning'}));

    }, [changeTitle]);


    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Paper className={classes.basePaper}>

            <Grid className={classes.headers} container spacing={3}>
                {headers}
            </Grid>

            <TabContext  value={tab}>
                <AppBar position="static">
                    <TabList onChange={handleTabChange} aria-label="Related Data">
                        {tabs}
                    </TabList>
                </AppBar>
                {panels}
            </TabContext>
        </Paper>
    );
};

export default Show;