import { createMuiTheme } from '@material-ui/core/styles'

// Light or default theme
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00448f',
        },
        secondary: {
            main: '#344b57',
        },
        error: {
            main: '#cb1e1e',
        },
        danger: {
            main: '#cb1e1e',
        },
        background: {
            default: '#f5f5f5',
        },
        titleBar: {
            main: '#eeeeee',
            contrastText: '#222222',
        },
    },
    overrides: {
        MuiDrawer: {
            paper: {
                background: '#434343',
                color: '#e7e7e7',
                "& .MuiListItemIcon-root": {
                    color: "inherit"
                },
                "& .MuiDivider-root": {
                    backgroundColor: "#717171",
                    opacity: 0.3
                },
                "& .MuiListSubheader-root": {
                    color: "#e7e7e7"
                },
                "& .MuiListItem-button:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.08)"
                },
                "& .MuiIconButton-root": {
                    color: "#e7e7e7"
                }
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#fff',
                color: 'inherit'
            },
        },
    },
})

export default theme