import { createMuiTheme } from '@material-ui/core/styles'

// Dark theme
const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#FFCF51',
            light: 'rgb(81, 91, 95)',
            dark: 'rgb(26, 35, 39)',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#FFB74D',
            light: 'rgb(255, 197, 112)',
            dark: 'rgb(200, 147, 89)',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        titleBar: {
            main: '#555555',
            contrastText: '#ffffff',
        },
        danger: {
            main: '#cb1e1e',
        },
        error: {
            main: '#cb1e1e',
        },
    },
    overrides: {
        MuiDrawer: {
            paper: {
                background: '#424242',
                color: '#e7e7e7',
                "& .MuiListItemIcon-root": {
                    color: "inherit"
                },
                "& .MuiDivider-root": {
                    backgroundColor: "#717171",
                    opacity: 0.3
                },
                "& .MuiListSubheader-root": {
                    color: "#e7e7e7"
                },
                "& .MuiInputBase-root": {
                    backgroundColor: "#000"
                }
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#424242',
            }
        },
    },
})

export default theme