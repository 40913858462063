import React from "react";
import ReactLoading from "react-loading";
import Box from "@material-ui/core/Box";

const Loading = (height = null, width = null) => {

    return (
        <Box display="flex" width={height ? height : '100%'} height={width ? width : 80} alignItems="center" justifyContent="center" >
            <ReactLoading m="auto" type={'cylon'} color={'#d1d1d1'} />
        </Box>
    );
};

export default Loading;