import React, {useEffect} from 'react';
import {Paper} from "@material-ui/core";
import Table from "../../../components/Table/Table";
import ViewEditButtons from "../../../components/Table/ViewEditButtons";

const Orders = (props) => {

    const{changeTitle} = props;

    useEffect(() => {
        changeTitle("Orders");
    }, [changeTitle]);


    return (
        <Paper style={{height:815}}>
            <Table
                url="orders?address=true"
                meta_name="orders"
                buttons={ViewEditButtons("orders")}
            />
        </Paper>
    );

};

export default Orders;
