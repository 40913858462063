import React, {useEffect} from 'react';
import {Paper} from "@material-ui/core";
import Table from "../../../components/Table/Table";
import ViewEditButtons from "../../../components/Table/ViewEditButtons";

const Transactions = (props) => {

    const{changeTitle} = props;

    useEffect(() => {
        changeTitle(props.title);
    }, [changeTitle]);

    return (
        <Paper style={{height:815}}>
            <Table
                url={props.url}
                meta_name={props.meta_name}
                buttons={ViewEditButtons(props.button_url)}
            />
        </Paper>
    );

};

export default Transactions;
