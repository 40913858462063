import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: 500,
        overflowY: "auto"
    },
}));

export default function SimpleModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(props.open === true);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAction = (event) => {
        props.handleAction(event);
        handleClose();
    }

    const OpenButton = () => {
        return (
            <Button onClick={handleOpen}>
                {props.openButtonText ? props.openButtonText : "Open Modal"}
            </Button>
        );
    }

    const CloseButton = () => {
        return (
            <Button onClick={handleClose}>
                {props.closeButtonText ? props.closeButtonText : "Close"}
            </Button>
        );
    }

    const ActionButton = () => {
        return (
            <Button variant="outlined" color="primary" onClick={handleAction}>
                {props.actionButtonText ? props.actionButtonText : "Do It!"}
            </Button>
        );
    }

    return (
        <div>
            {props.openButton ? OpenButton : null}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">{props.title}</h2>
                        <div id="transition-modal-description">{props.body}</div>
                        <Box display="flex" justifyContent="flex-end">
                            {props.closeButton ? CloseButton() : null}
                            {props.actionButton ? ActionButton() : null}
                        </Box>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}