import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Paper, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import api from "../../../services/api";
import Button from "@material-ui/core/Button";
import {parseResponse} from "../../../services/helpers";
import {useSnackbar} from "notistack";
import history from "../../../services/history";
import {useParams} from "react-router-dom";
import AsyncSelect from "../../../components/AsyncSelect/AsyncSelect";
import Confirm from "../../../components/Confirm/Confirm";

const useStyles = makeStyles((theme) => ({
    basePaper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        background: theme.background,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        minHeight: 815
    },
    root: {
        flexGrow: 1,
    },
    paper:{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
        background: theme.background,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },

}));

const Edit = (props) => {
    const classes = useStyles();
    const {changeTitle} = props;
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [items, setItems] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {id} = useParams();

    useEffect(() => {
        let title = `Item Group: ${id}`;

        api.get(`item-groups/${id}`)
            .then(response => {
                console.log(response);
                setName(response.data.data.name);
                setDescription(response.data.data.description);
                setItems(response.data.data.items && response.data.data.items.length ? response.data.data.items : []);
                changeTitle(`Edit ${title && response.data.data.name}`);
            });

    }, [changeTitle]);

    const handleDelete = () => {
        api.get(`item-groups/delete/${id}`)
            .then(response => {
                enqueueSnackbar(parseResponse(response), {variant: 'success'});
                history.push('/item-groups');
            })
            .catch(error => {
                if (error.response && error.response.status === 422) {
                    console.log(error, error.response);
                    enqueueSnackbar(parseResponse(error), {
                        variant: 'warning',
                    });
                } else {
                    enqueueSnackbar('An internal error has occurred.', {variant: 'error'});
                }
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        changeTitle(`Edit ${name}`);

        api.post(`/item-groups/${id}`,{
            name: name,
            description: description,
            items: items
        }).then(response => {
            console.log(response);
            enqueueSnackbar(parseResponse(response), {
                variant: 'success',
            });
        }).catch(error => {
            console.log(error);
            if (error.response && error.response.status === 422) {
                console.log(error, error.response);
                enqueueSnackbar(parseResponse(error), {
                    variant: 'warning',
                });
            } else {
                enqueueSnackbar('An internal error has occurred.', {variant: 'error'});
            }
        });
    }

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item md={12}>
                <Paper className={classes.paper}>
                    <Grid container
                          alignItems="center"
                          spacing={2}>
                        <Grid
                            container
                            direction={"row"}
                            justify={"flex-end"}
                            alignItems={"center"}
                            spacing={2}
                        >
                            <Grid item>
                                <Button onClick={() => setConfirmOpen(true)} variant="outlined">
                                    Delete
                                </Button>
                                <Confirm
                                    title="Delete Item Group?"
                                    open={confirmOpen}
                                    setOpen={setConfirmOpen}
                                    onConfirm={handleDelete}
                                >
                                    Are you sure you want to delete this this Item Group?
                                </Confirm>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleSubmit} variant="contained" color="primary">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid item md={4}>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Name"
                                name="name"
                                id="name"
                                autoFocus
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Description"
                                name="description"
                                id="description"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <AsyncSelect
                                url='/items?pagination=1000000'
                                size='small'
                                option_label='long_description'
                                label='Items'
                                handleChange={(e, selectItems) => setItems(prevItems => selectItems)}
                                defaultValues={items}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>

    );
};

export default Edit