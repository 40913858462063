import React, {useEffect} from 'react';

const Reports = (props) => {
    // const [reports, setReports] = React.useState([]);

    const{changeTitle} = props;

    useEffect(() => {
        changeTitle("Reports");
    }, [changeTitle]);

    if (props.loggedIn) {
        return (
            <div className="list-group">Reports List</div>
        );
    }
    return (
        <div className="alert alert-warning">Reports not available. You are not logged in.</div>
    );
};

export default Reports;