import React from "react";
import {useLocation} from "react-router";

export const parseResponse = (apiResponse) => {
    let message;
    if(apiResponse && apiResponse.response){
        console.warn('Response Error: ', apiResponse.response);
        const errors = apiResponse.response.data && apiResponse.response.data.errors ? apiResponse.response.data.errors : null;
        if(errors){
            message = '';
            for (const error in errors) {
                message += ` ${errors[error]} \n`;
            }
        }

        return (
            <span>
                {apiResponse.response.data.message}
                {message ? `\n ${message}` : null}
            </span>
        )
    }

    console.log(apiResponse);



    if(apiResponse && apiResponse.data && apiResponse.data.message){
        // console.info('Response Data: '+apiResponse.data);
        return <span>{apiResponse.data.message}</span>
    }

    if(apiResponse && apiResponse.data && apiResponse.data.data && apiResponse.data.data.message){
        // console.info('Response Data: '+apiResponse.data);
        return <span>{apiResponse.data.data.message}</span>
    }

    if(apiResponse && apiResponse.statusText){
        let message = apiResponse.statusText;
        message = apiResponse.statusText === 'Created' ? 'Created Successfully.' : message;
        message = apiResponse.statusText === 'OK' ? 'Saved Successfully.' : message;

        return <span>{message}</span>
    }

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.error('Development Response Error: '+apiResponse);
        return <span>{apiResponse.toString()}</span>;
    }

    return <span>An error has occurred</span>;
}

export const toUpperCaseWords = str => str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

export const wordInString = (str, word) => new RegExp('\\b' + word + '\\b', 'i').test(str);

export const unslugify = str => {
    str = str.replace('_', ' ');
    str = str.replace('-', ' ');
    str = toUpperCaseWords(str);
    if(wordInString(str, 'Id')){
        str = str.replace('Id', 'ID');
    }
    return str;
}

export const slugify = (str, spacer = '-') => str.replace(' ', spacer).toLowerCase();

export const useQuery = () => new URLSearchParams(useLocation().search);

export const isEmptyObject = obj =>
    Object.getOwnPropertyNames(obj).length === 0 &&
    Object.getOwnPropertySymbols(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype;

export const sleep = seconds => new Promise(resolve => setTimeout(resolve, seconds*1000));

export const deepClone = ( obj ) => {
    let key;
    if( !obj || true === obj ) //this also handles boolean as true and false
        return obj;
    const objType = typeof (obj);
    if( "number" === objType || "string" === objType ) // add your immutables here
        return obj;
    const result = Array.isArray(obj) ? [] : !obj.constructor ? {} : new obj.constructor();
    if( obj instanceof Map )
        for(key of obj.keys() )
            result.set( key, deepClone( obj.get( key ) ) );
    for(key in obj )
        if( obj.hasOwnProperty( key ) )
            result[key] = deepClone( obj[ key ] );
    return result;
}
