import React, {useEffect} from "react";
import {Paper} from "@material-ui/core";
import Table from "../../../components/Table/Table";
import ViewEditButtons from "../../../components/Table/ViewEditButtons";


const Items = props => {
    const{changeTitle} = props;

    useEffect(() => {
        changeTitle('Items');
    }, [changeTitle]);

    return (
        <Paper style={{height:815}}>
            <Table
                url={'items'}
                meta_name={'items'}
                // buttons={ViewEditButtons(props.button_url)}
            />
        </Paper>
    );
}

export default Items;