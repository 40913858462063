import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import api from "../../../services/api";


const ItemRow = (props) => {
    const [items, setItems] = useState([]);

    useEffect(() => {

        api.get(`items`)
            .then(response => {
                const itemOptions = [];
                for (const [key, value] of Object.entries(response.data)) {
                    itemOptions.push(<MenuItem value={key}>{value}</MenuItem>)
                }
                setItems(itemOptions);
            });

    }, []);

    return (
        <React.Fragment>
            <Grid container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                  spacing={2}>
                <Grid item xs={8}>
                    <FormControl margin={"normal"} size="small" required fullWidth variant="outlined" >
                        <InputLabel id="demo-simple-select-outlined-label">Item</InputLabel>
                        <Select
                            value='US'
                            // onChange={e => setShipMethod(e.target.value)}
                            label="Item"
                        >
                            { items }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                        <TextField
                            size="small"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Qty"
                            name="quantity"
                            id="quantity"
                            value={1}
                            // onChange={e => setQuantity(e.target.value)}
                        />
                </Grid>
                <Grid item xs={1} spacing={2}>
                    <Grid container
                          direction="row"
                          alignItems="center"
                          justify="flex-end">
                        <IconButton size="small" aria-label="delete" >
                            <DeleteIcon  />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ItemRow;