import React, {useEffect} from "react";
import {useSnackbar} from "notistack";
import api from "../../../../services/api";

const Netsuite = (props) => {
    const [orders, setOrders] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const {changeTitle, isAuthenticated} = props;

    useEffect(() => {
        changeTitle("Netsuite");

        if (isAuthenticated) {
            api.get('orders')
                .then(response => {
                    setOrders(response.data);
                })
                .catch(error => console.error(error));
        }else{
            enqueueSnackbar('Transaction not available. You are not logged in.', {variant: 'error'});
        }
    }, [changeTitle, enqueueSnackbar, isAuthenticated]);

    const orderList = orders.map((order) =>
        <div key={order.id}
             className="list-group-item"
        >
            <h5>{order.title}</h5>
            <small>{order.author}</small>
        </div>
    );


    return (
        <div className="list-group">{orderList}</div>
    );

};

export default Netsuite;
