import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from "../../services/api";
import {slugify} from "../../services/helpers";
import PropTypes from "prop-types";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function AsyncSelect(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const {url, option_label, label, defaultValues} = props;

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const response = await api.get(url);
            // await sleep(1e3); // For demo purposes.
            const data = await response.data.data;
            
            if (active) {
                if(data && data.length){
                    // its an array
                    setOptions(data);
                }else{
                    // its an object
                    setOptions(Object.keys(data).map((key) => data[key].item[0]));
                }
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            multiple
            id={slugify(label)+'_select'}
            style={{ width: '100%' }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option[option_label] === value[option_label]}
            getOptionLabel={(option) => option[option_label]}
            onChange={props.handleChange}
            options={options}
            loading={loading}
            size={"medium" && props.size}
            value={defaultValues}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

AsyncSelect.propTypes = {
    url: PropTypes.string.isRequired,
    size: PropTypes.string,
    option_label: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired
};