import axios from 'axios';

const extensions = ['com', 'net', 'org', 'io', 'dev', 'biz'];
const subdomain = window.location.host.split('.')[1] && !extensions.includes(window.location.host.split('.')[1]) ? window.location.host.split('.')[0] : false;

let baseUrl = `https://${subdomain ? subdomain+'.' : ''}${process.env.REACT_APP_DOMAIN}/api/`;
if(process.env.NODE_ENV === 'development'){
    baseUrl = `https://${process.env.REACT_APP_DEV_SUBDOMAIN}.${process.env.REACT_APP_DOMAIN}/api/`;
}

const api = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
});

api.defaults.headers.common['Content-Type'] = 'application/json';
api.defaults.headers.common['Accept'] = 'application/json';

if(process.env.NODE_ENV === 'development') {
    console.log(process.env.REACT_APP_DEV_SUBDOMAIN, process.env.REACT_APP_DOMAIN, process.env.REACT_APP_DEV_TOKEN);
    api.defaults.headers.common['Authorization'] = 'Bearer '+process.env.REACT_APP_DEV_TOKEN;
}

export default api;

