import React, {useEffect} from "react";
import {Paper} from "@material-ui/core";
import Table from "../../../components/Table/Table";
import ViewEditButtons from "../../../components/Table/ViewEditButtons";


const ItemGroups = props => {
    const{changeTitle} = props;

    useEffect(() => {
        changeTitle('Item Groups');
    }, [changeTitle]);

    return (
        <Paper style={{height:815}}>
            <Table
                url={'item-groups'}
                meta_name={'item_groups'}
                buttons={ViewEditButtons("item-groups")}
            />
        </Paper>
    );
}

export default ItemGroups;