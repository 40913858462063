import React, {useEffect, useState} from 'react';
import api from '../../../../services/api';
import {toUpperCaseWords} from "../../../../services/helpers";
import { DataGrid } from '@material-ui/data-grid';
import Loading from "../../../../components/Loading/Loading";
import {Card, Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "../../../../components/Table/Table";
import ViewEditButtons from "../../../../components/Table/ViewEditButtons";

const Employees = (props) => {
    const{changeTitle} = props;

    useEffect(() => {
        changeTitle("Employees");
    }, [changeTitle]);

    const handleSyncEmployees = () => {
        api.get('employees/sync')
            .then(response => {
                console.log(response);
            })
            .catch(reason => {
                console.log(reason);
            })
    }

    return (
        <Paper style={{ height: 800, width: '100%' }}>
            <Grid
                container
                direction={"row"}
                justify={"flex-end"}
                alignItems={"center"}
            >
                <Grid item xs={2}>
                    <Button
                        onClick={handleSyncEmployees}
                        variant={"contained"}
                        size={"small"}
                        color={"primary"}>
                        Sync Employees
                    </Button>
                </Grid>
            </Grid>
            <Table
                url={'employees'}
                meta_name={'employees'}
                buttons={ViewEditButtons("employees")}
                autoHeight={true}
            />
        </Paper>
    )


    return <Loading />;

};

export default Employees;
