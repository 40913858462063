import React, {useEffect} from 'react';
import {Paper} from "@material-ui/core";
import Table from "../../../components/Table/Table";
import ViewEditButtons from "../../../components/Table/ViewEditButtons";

const Returns = (props) => {

    const{changeTitle} = props;

    useEffect(() => {
        changeTitle("Returns");
    }, [changeTitle]);

    return (
        <Paper style={{height:815}}>
            <Table
                url="returns"
                meta_name="returns"
                buttons={ViewEditButtons("returns")}
            />
        </Paper>
    );
};

export default Returns;