import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InputBase from "@material-ui/core/InputBase";

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import MoreIcon from "@material-ui/icons/MoreVert";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Badge from '@material-ui/core/Badge';
import {CustomThemeContext} from "../Themes/CustomThemeProvider";
import {
    Collapse,
    FormControlLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList
} from "@material-ui/core";
import Auth from "../../routes/Auth";
import SwitchUI from '@material-ui/core/Switch';
import {Switch, Route, withRouter, Link as domLink} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Copyright from "../Footer/Copyright";
import api from "../../services/api";
import {parseResponse} from "../../services/helpers";
import {useSnackbar} from "notistack";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#e7e7e7',
        "&:hover": {
            backgroundColor: '#e8e8e8'
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        color: "#000",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: '#000'
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 200
        },
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: '#000 !important'
        },
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    danger: {
        color: "red",
    }
}));

const Navigation = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const activeRoute = (routeName) => {
        return props.location.pathname === routeName;
    }

    // theme changer
    const { currentTheme, setTheme } = useContext(CustomThemeContext);
    const isDark = Boolean(currentTheme === 'Dark');
    const handleThemeChange = (event) => {
        const { checked } = event.target
        if (checked) {
            setTheme('Dark')
        } else {
            setTheme('Light')
        }
    }

    const [anchorEl, setAnchorEl] = useState(false);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(false);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(false);
    };

    const handleMenuClose = () => {
        setAnchorEl(false);
        setMobileMoreAnchorEl(false);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const [open, setOpen] = useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const menuId = "primary-search-account-menu";

    const handleLogout = () => {
        api.post('web/logout')
            .then(response => {
                if (response.status === 204) {
                    props.handleLogout();
                }
            }).catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    props.handleLogout();
                }
                if (error.response && error.response.status === 422) {
                    enqueueSnackbar(parseResponse(error), {
                        variant: 'warning',
                    });
                }
        });
    }

    const [title, setTitle] = useState(process.env.REACT_APP_NAME);
    const handleTitleChange = (title) => {
        if(title){
            document.title = title;
            setTitle(title);
        }else{
            document.title = process.env.REACT_APP_NAME;
            setTitle(process.env.REACT_APP_NAME);
        }
    }

    const [menuName, setMenuName] = useState();
    const handleMenuItemChange = (menuName) => {
        setMenuName((prevMenuName) => {
            return prevMenuName !== menuName ? menuName : null;
        });
    }

    const menuHandler = ( menuItems, inset=false) => {
        return menuItems.filter(menuItem => menuItem.name).map( (menuItem) => {
            if ( !menuItem.subItems ) {
                return (
                    <div key={ menuItem.name } style={inset ? {backgroundColor: '#424242'} : null}>
                        <ListItem
                            button
                            key={menuItem.name+menuItem.route}
                            component={domLink}
                            to={menuItem.route}
                            selected={activeRoute(menuItem.route)}
                            style={inset ? {paddingLeft: 32} : null}
                        >
                            {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                            <ListItemText primary={menuItem.name} />
                        </ListItem>
                    </div>
                )
            }
            return (
                <div key={ menuItem.name+menuItem.route } style={inset ? {backgroundColor: '#424242'} : null}>
                    <ListItem
                        button
                        onClick={ () => handleMenuItemChange( menuItem.name )}
                        selected={activeRoute(menuItem.route)}
                        style={inset ? {paddingLeft: 32} : null}
                    >
                        {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                        <ListItemText primary={ menuItem.name } />
                        { menuName === menuItem.name ? <ExpandLess /> : <ExpandMore /> }
                    </ListItem>
                    <Collapse in={ menuName === menuItem.name } timeout="auto" unmountOnExit >
                        { menuItem.subItems.length ? menuHandler( menuItem.subItems, true ) : null }
                    </Collapse>
                </div>
            )
        })
    }

    const switchItems = [];
    const switchHandler = (menuItems) => {
        switchElementHandler(menuItems);
        return switchItems
    }

    const switchElementHandler = ( menuItems ) => {
        menuItems.forEach((menuItem, i) => {
            if (menuItem.subItems ) {
                switchElementHandler(menuItem.subItems);
            }
            if(menuItem.component){
                const menuProps =  menuItem.props ? {...menuItem.props, ...props} : props;
                switchItems.push(
                    <Route exact path={menuItem.route} key={menuItem.name+menuItem.route} >
                        <menuItem.component { ...menuProps } changeTitle={handleTitleChange} />
                    </Route>
                );
            }
        })
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My Account</MenuItem>
            <MenuItem>
                <span style={{paddingRight: 10}}>Dark Theme</span>
                <FormControlLabel
                    control={<SwitchUI checked={isDark} onChange={handleThemeChange} />}
                    label=""
                />
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="primary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="primary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        { title }
                    </Typography>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <IconButton aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={17} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>

                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <img src={process.env.REACT_APP_URL + '/lion_energy_text.png'} alt="LionEnergy Logo" width='160px'/>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <MenuList>
                    {menuHandler(Auth)}
                </MenuList>
                <Divider />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="xl" className={classes.container}>
                    <Switch>
                        {switchHandler(Auth)}
                    </Switch>
                    <Box pt={2}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
}

export default withRouter(Navigation);