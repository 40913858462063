import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {TableChartRounded} from "@material-ui/icons";
import {Popover, TextField} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {unslugify} from "../../services/helpers";

const HeadersButton = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [headers, setHeaders] = useState({});
    const {buttons, columns, classes} = props;

    const handleHeadersOpen = (event) => setAnchorEl(event.currentTarget);
    const handleHeadersClose = () => setAnchorEl(null);

    const handleHeadersSave = () => {
        props.handleHeadersSave(headers);
        handleHeadersClose();
    };

    const handleHeadersChange = (event) => {
        const headersData =  {[event.target.name]: event.target.value};
        setHeaders((prevHeaders) => { return {...prevHeaders, ...headersData} });
    }

    return (
        <React.Fragment>
            <Button
                color="primary"
                size="small"
                startIcon={<TableChartRounded />}
                className="toolbar-option"
                aria-controls="simple-popover"
                aria-haspopup="true"
                onClick={handleHeadersOpen}
                fullWidth >
                Headers
            </Button>
            <Popover
                id='simple-popover'
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleHeadersClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box pt={2} textAlign="center" width="100%">
                    <Typography variant="caption" display="block">Rename the table headers by<br/> changing the names below.</Typography>
                </Box>
                <form className={classes.root} noValidate autoComplete="off">
                    {
                        columns.filter(c => !buttons.map(b => b['field']).includes(c.field)).map((c) => {
                            return (
                                <Box mb={1} key={c.field}>
                                    <TextField size="small" key={c.field} name={c.field} label={unslugify(c.field)} defaultValue={c.headerName} variant="outlined" onChange={handleHeadersChange}>
                                        {c.headerName}
                                    </TextField>
                                </Box>
                            );
                        })
                    }
                    <Box width="100%">
                        <Button fullWidth={true} color="primary" variant="outlined" onClick={handleHeadersSave}>Save</Button>
                    </Box>
                </form>
            </Popover>
        </React.Fragment>
    );
}

export default HeadersButton;