import React from "react";
import {
    List,
    Add,
    Rotate90DegreesCcw,
    AssessmentOutlined,
    SettingsOutlined,
    DeveloperMode,
    AccountTreeOutlined,
    AssignmentIndOutlined,
    DashboardOutlined,
    AllOut,
    ChromeReaderModeOutlined
} from "@material-ui/icons";
import Orders from "../pages/Auth/Orders/Orders";
import Returns from "../pages/Auth/Returns/Returns";
import Reports from "../pages/Auth/Reports/Reports";
import Netsuite from "../pages/Auth/Settings/Netsuite/Netsuite";
import Employees from "../pages/Auth/Settings/Employees/Employees";
import ShowTransaction from "../pages/Auth/Transactions/Show";
import Transactions from "../pages/Auth/Transactions/Transactions";
import CreateOrder  from "../pages/Auth/Orders/Create";
import Items from "../pages/Auth/Items/Items";
import ItemGroups from "../pages/Auth/ItemGroups/ItemGroups";
import CreateItemGroup from "../pages/Auth/ItemGroups/Create";
import ShowItemGroup from "../pages/Auth/ItemGroups/Show";
import EditItemGroup from "../pages/Auth/ItemGroups/Edit";

const Auth = [
    {
        route: '/',
        component: Orders
    },
    {
        name: 'Orders',
        icon: <DashboardOutlined/>,
        subItems: [
            {
                name: 'Orders List',
                icon: <List/>,
                route: '/orders',
                component: Transactions,
                props: {title: 'Orders', url: 'orders?address=true', button_url: 'orders', meta_name: 'orders'}
            },
            {
                name: 'Create Order',
                icon: <Add/>,
                route: '/orders/create',
                component: CreateOrder
            },
            {
                route: '/orders/:id',
                component: ShowTransaction,
                props: {title: 'Orders', url: 'orders'}
            },
            {
                route: '/orders/edit/:id',
                component: ShowTransaction,
                props: {title: 'Orders', url: 'orders'}
            },
        ]
    },
    {
        name: 'Returns List',
        icon: <Rotate90DegreesCcw/>,
        route: '/returns',
        component: Transactions,
        props: {title: 'Returns', url: 'returns?address=true', button_url: 'returns', meta_name: 'returns'}
    },
    {
        route: '/returns/create/:id',
        component: Returns
    },
    {
        route: '/returns/:id',
        component: ShowTransaction,
        props: {title: 'Returns', url: 'returns'}
    },
    {
        route: '/returns/edit/:id',
        component: ShowTransaction,
        props: {title: 'Returns', url: 'returns'}
    },
    {
        name: 'Reports',
        icon: <AssessmentOutlined/>,
        route: '/reports',
        component: Reports
    },
    {
        name: 'Item Groups',
        icon: <ChromeReaderModeOutlined/>,
        subItems: [
            {
                name: 'Item Groups List',
                icon: <List/>,
                route: '/item-groups',
                component: ItemGroups
            },
            {
                name: 'Create Item Group',
                icon: <Add/>,
                route: '/item-groups/create',
                component: CreateItemGroup
            },
            {
                route: '/item-groups/:id',
                component: ShowItemGroup
            },
            {
                route: '/item-groups/edit/:id',
                component: EditItemGroup
            },
        ]
    },
    {
        name: 'Items',
        icon: <AllOut/>,
        route: '/items',
        component: Items
    },
    {
        name: 'Settings',
        icon: <SettingsOutlined/>,
        subItems: [
            {
                name: 'Netsuite',
                icon: <AccountTreeOutlined/>,
                route: '/settings/netsuite',
                component: Netsuite
            },
            {
                name: 'Queries',
                icon: <DeveloperMode/>,
                route: '/settings/queries',
                component: Orders
            },
            {
                name: 'Employees',
                icon: <AssignmentIndOutlined/>,
                route: '/settings/employees',
                component: Employees
            }
        ]
    }
];

export default Auth;