import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {GridToolbar, GridColumnsToolbarButton, GridDensitySelector, GridToolbarExport, GridFilterToolbarButton, useGridApiRef, XGrid} from "@material-ui/x-grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import {GridToolbarContainer} from "@material-ui/data-grid";
import {Menu, Tooltip} from "@material-ui/core";
import {ViewModule, Save as SaveIcon, Menu as MenuIcon, Delete} from "@material-ui/icons";
import PropTypes from "prop-types";
import api from "../../services/api";
import {deepClone, isEmptyObject, parseResponse, unslugify} from "../../services/helpers";
import Loading from "../Loading/Loading";
import {useSnackbar} from "notistack";
import HeadersButton from "./HeadersButton";
import RadioModal from "./RadioModal";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DeleteIcon from "@material-ui/icons/Delete";
import Confirm from "../Confirm/Confirm";
import history from "../../services/history";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: 15,
        "& .MuiFormGroup-options": {
            alignItems: "center",
            paddingBottom: theme.spacing(1),
            "& > div": {
                minWidth: 100,
                margin: theme.spacing(2, 2, 2, 0)
            }
        },
        "& .toolbar-options": {
            paddingTop: 4,
            paddingRight: 4,
            paddingBottom: 0,
            paddingLeft: 4,
            marginLeft: 'auto',
            display: "flex",
            alignItems: "center",
            "& .toolbar-option": {
                paddingLeft: 8,
                justifyContent: "flex-end"
            },
            "& .toolbar-save-settings span span": {
                marginRight: 0,
            }
        }
    }
}));
const useStylesGridDesign = makeStyles(theme => ({
    root: {
        border: `1px solid ${
            theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
        color:
            theme.palette.type === "light"
                ? "rgba(0,0,0,.85)"
                : "rgba(255,255,255,0.85)",
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(","),
        WebkitFontSmoothing: "auto",
        letterSpacing: "normal",
        "& .MuiDataGrid-columnsContainer": {
            backgroundColor: theme.palette.type === "light" ? "#fafafa" : "#1d1d1d"
        },
        "& .MuiDataGrid-iconSeparator": {
            opacity: 0
        },
        "& .MuiDataGrid-colCell, .MuiDataGrid-cell": {
            borderRight: `1px solid ${
                theme.palette.type === "light" ? "#f0f0f0" : "#303030"
            }`
        },
        "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
            borderBottom: `1px solid ${
                theme.palette.type === "light" ? "#f0f0f0" : "#303030"
            }`
        },
        "& .MuiDataGrid-cell": {
            color:
                theme.palette.type === "light"
                    ? "rgba(0,0,0,.85)"
                    : "rgba(255,255,255,0.65)"
        },
        "& .MuiPaginationItem-root": {
            borderRadius: 0
        },
        "& .MuiCheckbox-root svg": {
            width: 16,
            height: 16,
            backgroundColor: "transparent",
            border: `1px solid ${
                theme.palette.type === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
            }`,
            borderRadius: 2
        },
        "& .MuiCheckbox-root svg path": {
            display: "none"
        },
        "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
            backgroundColor: "#1890ff",
            borderColor: "#1890ff"
        },
        "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
            position: "absolute",
            display: "table",
            border: "2px solid #fff",
            borderTop: 0,
            borderLeft: 0,
            transform: "rotate(45deg) translate(-50%,-50%)",
            opacity: 1,
            transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
            content: '""',
            top: "50%",
            left: "39%",
            width: 5.71428571,
            height: 9.14285714
        },
        "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
            width: 8,
            height: 8,
            backgroundColor: "#1890ff",
            transform: "none",
            top: "39%",
            border: 0
        }
    }
}));

const Table = ( props => {
    const classes = useStyles();
    const gridDesignClasses = useStylesGridDesign();
    const [isGridDesign, setIsGridDesign] = useState(false);
    const apiRef = useGridApiRef();
    const [data, setData] = useState([]);
    const [meta, setMeta] = useState({});
    const [urlParams, setUrlParams] = useState({});
    const [paramCount, setParamCount] = useState(0);
    const [columns, setColumns] = useState([]);
    const [isMobileView, setIsMobileView] = useState(false);
    const [isCheckableForComplexData, setIsCheckableForComplexData] = useState(false);
    const [complexData, setComplexData] = useState({});
    const [confirmOpen, setConfirmOpen] = useState(false);
    const isInitialEffectCalled = useRef(false);
    const isParamCountImportant = useRef(true);
    const {enqueueSnackbar} = useSnackbar();
    const {url, meta_name, buttons, autoHeight} = props;

    useEffect(() => {
        // only run on first render
        if(isInitialEffectCalled.current){
            return;
        }
        // get the settings for the table
        api.get(`view-filters/${meta_name}`)
            .then( response => {
                const tableStructure = response.data && response.data.data && response.data.data.table_meta ? response.data.data.table_meta : null;
                if(tableStructure){
                    let count = 0;
                    setColumns(buttons ? [...buttons, ...tableStructure.columns] : tableStructure.columns);
                    console.log('tableStructure', tableStructure, 'apiRef', apiRef);

                    if(tableStructure.pageSize){
                        apiRef.current.setPageSize(tableStructure.pageSize);
                        count++
                    }

                    if(tableStructure.sort_by){
                        apiRef.current.sortColumn(tableStructure.columns.filter(c => c.field === tableStructure.sort_by[0].field)[0], tableStructure.sort_by[0].sort);
                        count++
                    }

                    if(tableStructure.filters){
                        tableStructure.filters.forEach((filter, index) => {
                            apiRef.current.applyFilter({
                                columnField: filter.columnField,
                                operatorValue: filter.operatorValue,
                                value: filter.value
                            });
                        });
                        // count++ <--- add this when you figure out how to filter programmatically and can trigger the handleFilterChange function below.
                    }

                    if(tableStructure.density){
                        apiRef.current.setDensity(tableStructure.density.value);
                        count++
                    }

                    if(tableStructure.theme){
                        setIsGridDesign(tableStructure.theme === 'Grid');
                    }
                    
                    if(tableStructure.complex_data){
                        setComplexData(tableStructure.complex_data);
                    }

                    setParamCount(count);
                }else{
                    // toggle the ParamCount so that it triggers the "Update Only useEffect" below
                    setParamCount(1); setParamCount(0);
                    enqueueSnackbar('Edit Table and Save Settings to create default View Filter.', {variant: 'info'});
                    enqueueSnackbar('No View Filter found.', {variant: "default"});
                }
            })
            .catch(error => enqueueSnackbar(parseResponse(error), {variant: 'warning'}));

    }, [enqueueSnackbar, apiRef, meta_name, buttons]);
    useEffect(() => {
        // only run on update (not first render)
        if(!isInitialEffectCalled.current){
            isInitialEffectCalled.current = true;
            return;
        }

        const uri = () => {
            let uri = url;
            let count = 0
            const joiner = uri.includes("?") ? "&" : "?";
            if (!isEmptyObject(urlParams)) {
                const queryString = Object.keys(urlParams).map(key => {
                    count++;
                    return key + '=' + urlParams[key];
                }).join('&');

                uri = uri + joiner + queryString;
            }

            // insuring the paramCount & count are equal enforces
            // that we only request our data one time.
            // After all the updates to the url have been
            // added from our "First-Time-Only useEffect".
            let completeUrl = null;
            if(count === paramCount && isParamCountImportant.current) {
                isParamCountImportant.current = false;
                completeUrl = uri;
            }else if(!isParamCountImportant.current){
                completeUrl = uri;
            }

            // if(completeUrl) console.log(completeUrl);
            return completeUrl;
        };

        const tableRequestUrl = uri();
        if(tableRequestUrl){
            api.get(tableRequestUrl)
                .then(response => {
                    response.data && response.data.meta ?
                        setMeta(response.data.meta) :
                        enqueueSnackbar('Pagination not enabled in API.', {variant: 'warning'});

                    const responseData = response.data.data;
                    if(columns.length){
                        if(complexData && complexData.mapped){
                            const mappedData = responseData.map(r => {
                                complexData.mapped.forEach((mapping) => {
                                   if(r[mapping.field]){
                                       r[mapping.field] = r[mapping.field][mapping.data];
                                   }
                                });
                                return r;
                            });

                            setData(mappedData);
                        }else{
                            setData(responseData);
                        }
                    }else{
                        // because we've never seen this data before and we have no
                        // view-filter - it's checkAbleForComplexData.
                        setIsCheckableForComplexData(true);
                        const columnKeys = Object.keys(response.data.data[0])
                        const columnNames = columnKeys.map(key => { return { field: key, headerName: unslugify(key) }});
                        setColumns(columnNames);
                        setData(responseData);
                    }
                })
                .catch(error => enqueueSnackbar(parseResponse(error), {variant: 'warning'}));
        }

    }, [urlParams, url, meta_name, enqueueSnackbar, paramCount]);
    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setIsMobileView(true )
                : setIsMobileView(false);
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    const handleDeleteTableSettings = () => {
        api.get(`/view-filters/delete/${meta_name}`)
            .then(response => {
                enqueueSnackbar(parseResponse(response), {variant: 'success'});
                window.location.reload();
                // isInitialEffectCalled.current = false;
                // console.log(isInitialEffectCalled.current);
                // apiRef.current.setPageSize(100000000000);
                // apiRef.current.setPageSize(0);
            })
            .catch(error => {
                if (error.response && error.response.status === 422) {
                    console.log(error, error.response);
                    enqueueSnackbar(parseResponse(error), {
                        variant: 'warning',
                    });
                } else {
                    enqueueSnackbar('An internal error has occurred.', {variant: 'error'});
                }
            });
    }
    const TableThemeButton = (props) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleOpen = (event) => setAnchorEl(event.currentTarget);
        const handleClose = () => setAnchorEl(null);
        const handleSelection = (event) => {
            if(event.target.innerText){
                props.changeTheme(event.target.innerText);
            }
            handleClose();
        };

        return (
            <React.Fragment>
                <Button
                    color="primary"
                    size="small"
                    startIcon={<ViewModule />}
                    className="toolbar-option"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleOpen}
                    fullWidth >
                    Theme
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem selected={!isGridDesign} onClick={handleSelection}>Stacked</MenuItem>
                    <MenuItem selected={isGridDesign} onClick={handleSelection}>Grid</MenuItem>
                </Menu>
            </React.Fragment>
        );
    }
    const MobileSettings = (props) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleOpen = (event) => setAnchorEl(event.currentTarget);
        const handleClose = () => setAnchorEl(null);
        return (
            <React.Fragment>
                <Button
                    color="primary"
                    size="small"
                    startIcon={<MenuIcon/>}
                    className=""
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleOpen}>
                    Settings
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem><GridColumnsToolbarButton fullWidth /></MenuItem>
                    <MenuItem><GridFilterToolbarButton fullWidth /></MenuItem>
                    <MenuItem><GridDensitySelector fullWidth /></MenuItem>
                    <MenuItem><GridToolbarExport fullWidth /></MenuItem>
                    <MenuItem><TableThemeButton {...props} /></MenuItem>
                    <MenuItem><HeadersButton handleHeadersSave={handleHeadersSave} classes={classes} columns={columns} buttons={!!buttons ? buttons : []} {...props} /></MenuItem>
                    <MenuItem>
                        <Button onClick={handleSaveTableSettings}
                            color="primary"
                            size="small"
                            startIcon={<SaveIcon />}
                            className="toolbar-option"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            fullWidth>
                                Save Settings
                        </Button>
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    }
    const DesktopSettings = (props) => {
        return (
            <React.Fragment>
                <GridToolbar/>
                <div className="toolbar-options">
                    <div className="toolbar-option">
                        <TableThemeButton {...props} />
                    </div>
                    <div className="toolbar-option">
                        <HeadersButton handleHeadersSave={handleHeadersSave} classes={classes} columns={columns} buttons={!!buttons ? buttons : []} {...props} />
                    </div>
                    <div className="toolbar-option">
                        <ButtonGroup disableElevation variant="text" size="small" color="primary" >
                            <Tooltip title="Save Settings">
                                <Button
                                    onClick={handleSaveTableSettings}
                                    startIcon={<SaveIcon />}
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                >
                                    Settings
                                </Button>
                            </Tooltip>
                            <Tooltip title="Delete Settings">
                                <Button
                                    onClick={() => setConfirmOpen(true)}
                                    className="toolbar-save-settings"
                                    startIcon={<DeleteIcon />}
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"/>
                            </Tooltip>
                            <Confirm
                                title="Delete Table Settings?"
                                open={confirmOpen}
                                setOpen={setConfirmOpen}
                                onConfirm={handleDeleteTableSettings}
                            >
                                This will remove the saved formatting from the table and reset it to defaults.
                                Are you sure you want to delete these table settings?
                            </Confirm>
                        </ButtonGroup>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    const TableToolbar = () => {
        return (
            <GridToolbarContainer>
                { isMobileView ? <MobileSettings changeTheme={handleThemeChange}/> : <DesktopSettings changeTheme={handleThemeChange}/> }
            </GridToolbarContainer>
        );
    }

    const handleFilterModelChange = (event) => {
        const filters = event.filterModel && event.filterModel.items && event.filterModel.items[0].value
            ? {filters: JSON.stringify(event.filterModel.items)}
            : {filters: 0};

            setUrlParams(prevUrlParams => { return {...prevUrlParams, ...filters }});
    };
    const handleSaveTableSettings = () => {
        if (apiRef && apiRef.current) {
            const state = apiRef.current.getState();
            const allColumns = apiRef.current.getAllColumns();
            const columnButtons = !!buttons ? buttons : [];
            // we have to strip out filterOperators &
            // filter out any fields that contain our props.buttons so we aren't saving them.
            const columns = allColumns.filter(c => !columnButtons.map(b => b['field']).includes(c.field)).map(c => {
                return {
                    align: c.align,
                    field: c.field,
                    filterable: c.filterable,
                    headerName: state.columns.lookup[c.field] && state.columns.lookup[c.field].headerName ? state.columns.lookup[c.field].headerName : c.headerName,
                    hide: complexData && complexData.hidden && complexData.hidden.includes(c.field) ? true : c.hide,
                    resizeable: c.resizeable,
                    sortable: c.sortable,
                    type: c.type,
                    width: c.width
                }
            });
            const meta_name = props.meta_name;

            const table_meta = {
                meta_name: meta_name,
                columns: columns,
                // filters : state.filter.items.length ? state.filter.items : null,
                density: state.density,
                theme: isGridDesign ? "Grid" : "Stacked",
                sort_by: state.sorting.sortModel.length ? state.sorting.sortModel : null,
                pageSize: urlParams.pagination ? urlParams.pagination : 100,
                complex_data: complexData ? complexData : null,
            }
            
            api.post('view-filters/' + meta_name, {
                name: meta_name,
                table_meta: table_meta
            }).then(response => {
                enqueueSnackbar('Settings saved successfully.', {
                    variant: 'success',
                });
            }).catch(error => enqueueSnackbar(parseResponse(error), {variant: 'warning'}));
        }
    }
    const handlePaginationChange = (event) => {
        const page = {page: event.page+1};
        setUrlParams(prevUrlParams => { return {...prevUrlParams, ...page }});
    };
    const handlePageSizeChange = (event) => {
        apiRef.current.setPage(0);
        const pageSize = {pagination: event.pageSize};
        setUrlParams(prevUrlParams => { return {...prevUrlParams, ...pageSize }});
    }
    const handleThemeChange = (theme) => {
        setIsGridDesign(theme === 'Grid');
    }
    const handleSort = (event) => {
        const sort = event.sortModel.length ? {order_by: JSON.stringify(event.sortModel)} : {order_by: 0};
        setUrlParams(prevUrlParams => { return {...prevUrlParams, ...sort }});
    };
    const handleHeadersSave = (headers) => {
        setColumns((prevColumns) => {
            const renamedColumns = deepClone(prevColumns);
            renamedColumns.map((c) => {
                if(headers[c.field]){
                    c.headerName = headers[c.field]
                }
                return c;
            })

            apiRef.current.updateColumns(renamedColumns, true);
            return renamedColumns;
        });

        handleSaveTableSettings();
    }

    const handleComplexDataSave = () => {

        // Set the data and columns here so the
        // changes all take place after all options
        // have been selected.
        if(complexData.data && !isEmptyObject(complexData.data)){
            setData(complexData.data);
            delete complexData.data;
        }
        if(complexData.columns && !isEmptyObject(complexData.columns)){
            setColumns(complexData.columns);
            delete complexData.columns;
        }
        
        handleSaveTableSettings();
    }
    const handleRadioChange = (event) => {
        const {name, value} = event.target;

        setComplexData((prevComplexData) => {
            let mappedData = prevComplexData.data ? prevComplexData.data : [];
            let relationalDataStructures = prevComplexData.mapped ? prevComplexData.mapped : [];
            let mappedColumns;
            let hiddenColumns = prevComplexData.hidden ? prevComplexData.hidden : [];

            if(value === 'hide'){
                const clonedColumns = prevComplexData.columns && prevComplexData.columns.length ? prevComplexData.columns : deepClone(columns);
                mappedColumns = clonedColumns.map(c => {
                    if(c.field === name){
                        c.hide = true;
                        hiddenColumns.push(name);
                    }
                    return c;
                });
            }else{
                const clonedData = prevComplexData.data && prevComplexData.data.length ? prevComplexData.data : deepClone(data);
                mappedData = clonedData.map(r => {
                    if(r[name]) {
                        r[name] = r[name][value];
                        const mapping = {field: name, data: value };
                        const fieldExists = relationalDataStructures.some(mappedInstance => mapping.field === mappedInstance.field);
                        if(!fieldExists){
                            relationalDataStructures.push(mapping);
                        }
                    }

                    return r;
                });

                const clonedColumns = prevComplexData.columns && prevComplexData.columns.length ? prevComplexData.columns : deepClone(columns);
                mappedColumns = clonedColumns.map(c => {
                    if(c.field === name){
                        c.sortable = false;
                        c.filterable = false;
                    }
                    return c;
                });
            }

            return {columns: mappedColumns, hidden: hiddenColumns, data: mappedData, mapped: relationalDataStructures}
        });
    };

    if(columns && meta && data){
        return (
            <div className={classes.root} style={!autoHeight ? {height: 815} : {}}>
                <RadioModal data={data} handleComplexDataSave={handleComplexDataSave} isCheckableForComplexData={isCheckableForComplexData} handleRadioChange={handleRadioChange}/>
                <XGrid
                    className={isGridDesign ? gridDesignClasses.root : undefined}
                    autoHeight={!!autoHeight}

                    components={{Toolbar: TableToolbar}}
                    rows={data ? data : {}}
                    columns={columns}
                    apiRef={apiRef}

                    onFilterModelChange={handleFilterModelChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePaginationChange}
                    onSortModelChange={handleSort}

                    rowsPerPageOptions={props.rowsPerPageOptions ? props.rowsPerPageOptions : [50, 100, 1000, 5000]}
                    rowCount={meta ? meta.total : 0}
                    pageSize={100}
                    pagination = {!props.rowsPerPageOptions && data.length > 49}

                    paginationMode="server"
                    sortingMode="server"
                    filterMode="server"
                />
            </div>
        );
    }

    return Loading;
});

Table.propTypes = {
    url: PropTypes.string.isRequired,
    meta_name: PropTypes.string.isRequired,
}

export default Table;
