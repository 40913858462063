import Light from './Light'
import Dark from './Dark'

const themes = {
    Light,
    Dark,
}

export default function getTheme(theme) {
    return themes[theme]
}