import {Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import {isEmptyObject, toUpperCaseWords} from "../../services/helpers";
import Box from "@material-ui/core/Box";
import * as React from "react";
import SimpleModal from "../Modal/Modal";

const RadioModal = (props) => {
    const iterables = {};
    const {data, handleRadioChange, isCheckableForComplexData, handleComplexDataSave} = props;


    let lastKey = '';
    data.forEach((row, i) => {
        for (const [key, value] of Object.entries(row)) {
            if((lastKey !== key && value && value instanceof Object) || (lastKey !== key && value && value instanceof Array)){
                iterables[key] = value;
                lastKey = key;
            }
        }
    });

    const Radios = (iterable, parentKey) => {
        const radios = [<FormControlLabel value="hide" key="hide" name={parentKey} control={<Radio parent={parentKey} />} label={'Hide: '+toUpperCaseWords(parentKey)+' Column'} />];
        for (const [key, value] of Object.entries(iterable)) {
            if(value && typeof value === 'string'){
                radios.push(<FormControlLabel value={key} key={key} control={<Radio parent={parentKey} />} label={toUpperCaseWords(key)+': '+value} />);
            }
        }
        return radios;
    }

    const RadioGroupBlock = (iterables) => {
        const radioGroupArray = [];
        for (const [parentKey, value] of Object.entries(iterables)) {
            radioGroupArray.push(
                <Box mb={2} key={parentKey}>
                    <FormLabel component="legend">{toUpperCaseWords(parentKey)}</FormLabel>
                    <RadioGroup aria-label={parentKey} key={parentKey} name={parentKey} onChange={handleRadioChange}>
                        {Radios(value, parentKey)}
                    </RadioGroup>
                    <Divider/>
                </Box>
            )
        }
        if(radioGroupArray.length){
            return (<FormControl component="fieldset">{radioGroupArray}</FormControl>)
        }
    }

    if(!isEmptyObject(iterables) && isCheckableForComplexData){
        const optionsContents = RadioGroupBlock(iterables);
        const body = (
            <React.Fragment>
                <p>
                    Some of the data on your table is too complex to show in a single cell.
                    You have multiple options of what can be shown in these table cells.
                    <br/>
                    Please select what you want to show, or select <strong>Hide</strong>&nbsp;to not show this column on future renderings.
                </p>
                {optionsContents}
            </React.Fragment>
        )

        if(optionsContents){
            return <SimpleModal actionButton={true} actionButtonText="Save" handleAction={handleComplexDataSave} open={true} title="Complex Data" body={body} />;
        }
    }

    return <React.Fragment/>
}

export default RadioModal;