import Login from "../pages/Guest/Login.js";
import Register from "../pages/Guest/Register.js";
import RecoverPassword from "../pages/Guest/RecoverPassword.js";
import ResetPassword from "../pages/Guest/ResetPassword";

const Guest = [
    {
        text: 'Login',
        route: '/login',
        component: Login
    },
    {
        text: 'Recover Password',
        route: '/recover-password',
        component: RecoverPassword

    },
    {
        text: 'Reset Password',
        route: '/reset-password',
        component: ResetPassword

    },
    {
        text: 'Register',
        route: '/register',
        component: Register

    }
];

export default Guest;