import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Paper, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import api from "../../../services/api";
import Button from "@material-ui/core/Button";
import {parseResponse} from "../../../services/helpers";
import {useSnackbar} from "notistack";
import history from "../../../services/history";

const useStyles = makeStyles((theme) => ({
    basePaper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        background: theme.background,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        minHeight: 815
    },
    root: {
        flexGrow: 1,
    },
    paper:{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
        background: theme.background,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));

const Create = (props) => {
    const classes = useStyles();
    const {changeTitle} = props;
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        changeTitle(`Create Item Group`);
    }, [changeTitle]);

    function handleSubmit(e) {
        e.preventDefault();
        api.post('/item-groups', {
            name: name,
            description: description
        }).then(response => {
            console.log(response);
            enqueueSnackbar(parseResponse(response), {
                variant: 'success',
            });
            history.push(`/item-groups/edit/${response.data.data.id}`);
        }).catch(error => {
            console.log(error);
            if (error.response && error.response.status === 422) {
                console.log(error, error.response);
                enqueueSnackbar(parseResponse(error), {
                    variant: 'warning',
                });
            } else {
                enqueueSnackbar('An internal error has occurred.', {variant: 'error'});
            }
        });
    }

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item md={12}>
                <Paper className={classes.paper}>
                    <Grid container
                          alignItems="center"
                          spacing={2}>

                        <Grid item md={4}>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Name"
                                name="name"
                                id="name"
                                autoFocus
                                onChange={e => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Description"
                                name="description"
                                id="description"
                                onChange={e => setDescription(e.target.value)}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <Grid container justify="flex-end">
                                <Button onClick={handleSubmit} variant="contained" color="primary">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
        </Grid>

    );
};

export default Create