import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Paper, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import api from "../../../services/api";
import IconButton from "@material-ui/core/IconButton";
import {AddCircle} from "@material-ui/icons";
import ItemRow from "./Item";

const useStyles = makeStyles((theme) => ({
    basePaper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        background: theme.background,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        minHeight: 815
    },
    root: {
        flexGrow: 1,
    },
    paper:{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
        background: theme.background,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    formHorizontal: {
        marginRight: theme.spacing(1),
    },
    delete: {
        color: theme.palette.danger.main,
    },
    deleteBtn: {
        color: theme.palette.danger.main,
    },
    success: {
        color: theme.palette.success.main,
    },
    addBtn: {
        color: theme.palette.success.main,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Create = (props) => {
    const classes = useStyles();
    const {changeTitle} = props;
    const [countries, setCountries] = useState([]);
    const [items, setItems] = useState([]);
    const [template, setTemplate] = useState();

    useEffect(() => {
        changeTitle(`Create Order`);

        api.get(`countries`)
            .then(response => {
                console.log(response);
                const countryOptions = [];
                for (const [key, value] of Object.entries(response.data)) {
                    countryOptions.push(<MenuItem value={key}>{value}</MenuItem>)
                }
                setCountries(countryOptions);
            });

        // setTemplate(ItemRow(countries));

    }, [changeTitle]);
    
    const handleSubmit = () => {
        console.log('here');
    }

    // const addItems = (count) => {
    //     const newItems = [];
    //     for(let i = 0; i < count; i++){
    //         newItems.push(template)
    //     }
    //     setItems(prevItems => [...prevItems, newItems] )
    // }

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={6}>
                <Paper className={classes.paper} >
                    <Typography variant="subtitle1" gutterBottom>Shipping Address</Typography>
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Name"
                        name="name"
                        id="name"
                        autoFocus
                        // onChange={e => setName(e.target.value)}
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Address1"
                        name="address1"
                        id="address1"
                        // onChange={e => setAddress1(e.target.value)}
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Address2"
                        name="address2"
                        id="address2"
                        // onChange={e => setAddress2(e.target.value)}
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="City"
                        name="city"
                        id="city"
                        // onChange={e => setCity(e.target.value)}
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="State"
                        name="state"
                        id="state"
                        // onChange={e => setState(e.target.value)}
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Zip Code"
                        name="zipcode"
                        id="zipcode"
                        // onChange={e => setZipCode(e.target.value)}
                    />
                    <FormControl size="small" required fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                        <Select
                            value='US'
                            // onChange={e => setCountry(e.target.value)}
                            label="Country"
                        >
                            { countries }
                        </Select>
                    </FormControl>
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Email"
                        name="email"
                        id="email"
                        // onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Phone"
                        name="phone"
                        id="phone"
                        // onChange={e => setPhone(e.target.value)}
                    />
                </Paper>
                <Paper className={classes.paper} >
                    <Typography variant="subtitle1" gutterBottom>Notes</Typography>
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        multiline
                        rows={4}
                        label="Memo"
                        name="memo"
                        id="memo"
                        autoFocus
                        // onChange={e => setName(e.target.value)}
                    />
                </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
                <Paper className={classes.paper} >
                    <Typography variant="subtitle1" gutterBottom>Order Details</Typography>
                    <FormControl size="small" required fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Entity</InputLabel>
                        <Select
                            value='US'
                            // onChange={e => setEntity(e.target.value)}
                            label="Entity"
                        >
                            { countries }
                        </Select>
                    </FormControl>
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="PO Number"
                        name="otherrefnum"
                        id="otherrefnum"
                        // onChange={e => setOtherrefnum(e.target.value)}
                    />
                </Paper>


                <Paper className={classes.paper} >
                    <Typography variant="subtitle1" gutterBottom>Order Options</Typography>
                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox value="custbody_test_order" color="primary" />}*/}
                    {/*    label="Test Order"*/}
                    {/*/>*/}
                    <FormControlLabel
                        control={<Checkbox value="custbody_is_replacement_order" color="primary" />}
                        label="Replacement Order"
                    />
                    <FormControlLabel
                        control={<Checkbox value="custbody_is_high_priority" color="primary" />}
                        label="High Priority"
                    />
                    <FormControlLabel
                        control={<Checkbox value="custbody_is_will_call_order" color="primary" />}
                        label="Will Call"
                    />
                    <FormControl size="small" required fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Ship Method</InputLabel>
                        <Select
                            value='US'
                            // onChange={e => setShipMethod(e.target.value)}
                            label="Ship Method"
                        >
                            { countries }
                        </Select>
                    </FormControl>

                </Paper>


                <Paper className={classes.paper} >
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" gutterBottom>Order Items</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Grid container
                                  direction="row"
                                  alignItems="center"
                                  justify="flex-end">
                                <IconButton aria-label="add item"
                                            // onClick={addItems(1)}
                                            size="small"
                                            className={classes.addBtn}>
                                    <AddCircle  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <ItemRow />
                        <ItemRow />
                        <ItemRow />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>

    );
};

export default Create