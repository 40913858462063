import history from "../../services/history";
import IconButton from "@material-ui/core/IconButton";
import {Edit, Visibility} from "@material-ui/icons";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(-1.4),
    },
    extendedIcon: {
        marginRight: theme.spacing(-1.4),
    },
}));

const ViewEditButtons = (path) => {

    const classes = useStyles();

    return [
        {
            field: "view",
            headerName: "View",
            sortable: false,
            width: 30,
            align: "left",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                    const row = {};
                    const fields = params.api.getAllColumns().map((c) => c.field).filter((c) => c !== "__check__" && !!c);
                    fields.forEach(f => row[f] = params.getValue(f));
                    history.push(`${path}/${row.id}`);
                };

                return <IconButton aria-label="delete" color="primary" onClick={onClick} className={classes.margin} size="small">
                    <Visibility fontSize="inherit" />
                </IconButton>
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            width: 30,
            align: "left",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                    const row = {};
                    const fields = params.api.getAllColumns().map((c) => c.field).filter((c) => c !== "__check__" && !!c);
                    fields.forEach(f => row[f] = params.getValue(f));
                    history.push(`${path}/edit/${row.id}`);
                };

                return <IconButton aria-label="delete" color="primary" onClick={onClick} className={classes.margin} size="small">
                    <Edit fontSize="inherit" />
                </IconButton>;
            }
        }
    ];
}

export default ViewEditButtons;
